import $ from 'jquery';
import UIkit from 'uikit';
import '../Resources/scss/website/web.scss'

import '../../bundles/BackendBundle/public/js/price/priceImport';

UIkit;
window.UIkit = UIkit;
window.$ = $;

